import React, { forwardRef, useEffect, useImperativeHandle, useState, useCallback } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { connect } from "react-redux";
import moment from 'moment';
import axios from 'axios';
import { Tooltip } from 'antd';

import store from '../../../../store';
import { openPopup } from "../../../../store/actions/popup/popup";
import { CommonLabels } from "../../../../project/Defines";
import { PopupTypes } from "../../custom/popup/popup";
import Sheet from '../../custom/sheet/sheet';
import { ArraySum, CloneObject } from '../../../helper/common';
import { ValidateFields } from '../../../helper/validation';
import { MappingFields } from '../../../mapping/mapping';
import { Action } from '../../../config/action.config';
import { useSectionPopup } from '../../../hook/action/sectionPopup';
import Scroll from '../../scroll/scroll';
import { setValue } from '../../../hook/data/useData';
import { useFormValidation } from '../../../hook/data/useFormValidation';
import { Defines } from '../../../../project/Defines';
import DragDrop from '../../custom/dragDrop';
import { useDeleteReason } from '../../../hook/action/delete';

const BatchTypes = {
    soft: 'soft',
    hard: 'hard',
    no: 'no'
};

const Order = forwardRef((props, ref) => {
    const columns = props.config.List.Columns;
    const screenKey = props.config.Key;
    const master = props.config.Master;
    const actions = props.config.List.Actions;
    const data = props.data ?? [];
    const propsDataLength = data.length;
    const [newIndex, setNewIndex] = useState(propsDataLength);
    const customerId = props.object.customerId;

    const [totalPrice, setTotalPrice] = useState({
        value: ArraySum(data, 'price')
    });
    const MAX_SPACES = 30;
    const MAX_WEIGHT = 42800;
    const axiosConfig = () => ({
        headers: {
            'Authorization': 'Bearer ' + store.getState().auth.userToken
        }
    });
    const buildPriceUrl = endpoint => `/api/${endpoint}`;

    let initialBatch = BatchTypes.no;
    let initialSetIsIndividualPrice = false;
    if (data.length > 0) {
        switch (data[0].batch) {
            case 1:
                initialBatch = BatchTypes.hard;
                break;
            case 2:
                initialBatch = BatchTypes.soft;
                break;
            case 3:
            default:
                initialBatch = BatchTypes.no;
                break;

        }
        initialSetIsIndividualPrice = data[0].isIndividualPrice;
    }
    const [batch, setBatch] = useState(initialBatch);
    const [isIndividualPrice, setIsIndividualPrice] = useState(initialSetIsIndividualPrice);
    const setInitialStates = useCallback(() => {
        setNewIndex(propsDataLength);
        setTotalPrice({ value: ArraySum(data, 'price') });
        setBatch(initialBatch);
    }, [propsDataLength, data, initialBatch]);

    const [initCount, setInitCount] = useState(props.object._INIT_COUNT_);
    useEffect(() => {
        if (initCount !== props.object._INIT_COUNT_) {
            setInitCount(props.object._INIT_COUNT_);
            setInitialStates();
        }
    }, [initCount, props.object._INIT_COUNT_, setInitialStates]);

    const validate = useFormValidation(
        props.messageDispatch,
        data.filter(o => !o.cancel),
        //data,
        MappingFields,
        ValidateFields,
        columns,
        null,
        props.messageIdentityPrefix,
        null,
        props.type,
        null);

    useImperativeHandle(ref, () => ({
        validate() {
            let [passValidation] = validate();
            const newData = data.filter(i => !i.cancel);
            if (initialBatch === BatchTypes.hard && passValidation) {
                let message = '';

                const spaceCount = ArraySum(newData, 'space');
                const weight = ArraySum(newData, 'weight');
                const validDropLocation = newData.every(i => i.dropLocationId === newData[0].dropLocationId);
                const validRegion = newData.every(i => i.dropRegionId === newData[0].dropRegionId);
                const validRoute = newData.every(i => i.routeId === newData[0].routeId);

                if (!validDropLocation) {
                    message = `
                        Hard batch orders can't have different drop locations
                    `;
                } else if (!validRegion) {
                    message = `
                        Hard batch orders can't have different drop regions
                    `;
                } else if (!validRoute) {
                    message = `
                        Hard batch orders can't have different routes
                    `;
                } else if (spaceCount > MAX_SPACES && weight > MAX_WEIGHT) {
                    message = `
                        Hard batch order group spaces count can't have more than ${MAX_SPACES}
                        Hard batch order group weight count can't have more than ${MAX_WEIGHT}
                    `;
                } else if (spaceCount > MAX_SPACES) {
                    message = `
                        For a “Total amount of Space” can’t exceed ${MAX_SPACES} when grouping as a “Hard Batch”
                    `;
                } else if (weight > MAX_WEIGHT) {
                    message = `
                        Hard batch order group weight count can't have more than ${MAX_WEIGHT}
                    `;
                }

                if (spaceCount > MAX_SPACES || weight > MAX_WEIGHT || !validDropLocation || !validRegion || !validRoute) {
                    passValidation = false;

                    props.openAlert({
                        windowKey: 'wndConfirmClose',
                        type: PopupTypes.Alert,
                        title: `Can't Save`,
                        text: `${message}`,
                        buttonYesText: CommonLabels.Buttons.Close,
                    });
                }
            }

            return passValidation;
        }
    }));

    const sectionPopup = useSectionPopup(props.config.Popup.Title, 'orderComment', props.config.Popup);
    const deleteReasonData = useDeleteReason('', props.config.ReasonConfig.ResourceItem, props.config.ReasonConfig);

    const isUseTotalPrice = () => (batch === BatchTypes.hard || batch === BatchTypes.soft) && isIndividualPrice;

    const newRecordHandler = (event) => {
        event.preventDefault();
        newRecord();
    };

    const cloneLastRecordHandler = (event) => {
        event.preventDefault();
        cloneRecord();
    };

    const onActionRecordHandler = (record, action) => {
        switch (action) {
            case Action.CancelReason:
                const onCancel = d => {
                    const newData = CloneObject(data);
                    const currentData = newData[record.id];
                    currentData.cancel = true;
                    currentData.cancelReason = d.cancelReason;
                    // currentData.cancelBy = d.cancelBy;
                    // currentData.cancelDate = d.cancelDate;
                    // currentData.orderStatus = d.status;

                    props.dispatch({ type: props.type, value: newData });
                    updatePrice(newData, totalPrice.value, isUseTotalPrice());
                };

                deleteReasonData(data[record.id][master], data[record.id][screenKey], data[record.id], false, onCancel);
                break;
            case Action.Delete:
                deleteRecord(record);
                break;
            case Action.Edit:
                const onSave = d => {
                    const newData = CloneObject(data);
                    newData[record.id] = CloneObject(d);
                    props.dispatch({ type: props.type, value: newData });
                };
                sectionPopup(data[record.id], onSave);
                break;
            default:
                break;
        }
    };

    const deleteRecord = (record) => {
        if (data.length === 1) {
            return;
        }
        const existingRecords = data.filter(r => !r.cancel);
        const recordData = existingRecords.find((i) => i[props.keyFieldName] === existingRecords[record.id][props.keyFieldName]);
        let newData;
        if (recordData.isNew) {
            newData = CloneObject(data);
            newData = newData.filter((i) => i[props.keyFieldName] !== recordData[props.keyFieldName]);
            props.dispatch({ type: props.type, value: newData });
        }
        else {
            newData = CloneObject(data);
            const cancelDataRecord = newData.find((i) => i[props.keyFieldName] === recordData[props.keyFieldName]);
            cancelDataRecord.cancel = true;
            props.dispatch({ type: props.type, value: newData });
        }
        updatePrice(newData, totalPrice.value, isUseTotalPrice());
    };

    const newRecord = () => {
        const newData = CloneObject(data);
        const newRecord = {
            isNew: true
        };
        const firstRecord = newData[0];
        const index = (newIndex + 1);
        for (const f in firstRecord) {
            if (f === 'isNew') {
                continue;
            }
            if (f === props.keyFieldName) {
                newRecord[f] = index * -1;
            }
            else if (f === 'batch') {
                newRecord.batch = firstRecord.batch;
            }
            else if (f === 'routeId') {
                newRecord.routeId = 1;
            }
            else if (f === 'orderStatus') {
                newRecord.orderStatus = 'Open';
            }
            else if (f === 'orderIndicator') {
                newRecord.orderIndicator = 1;
            }
            else if (typeof firstRecord[f] === "boolean") {
                if (f === 'Warehouse') {
                    newRecord[f] = true;
                }
                else {
                    newRecord[f] = false;
                }
            }
            else if (typeof firstRecord[f] === "number") {
                newRecord[f] = null;
            }
            else if (f === 'inOrderPossitionIndex' || f === 'outOrderPossitionIndex') {
                newRecord[f] = 1;
            }
            else {
                newRecord[f] = null;
            }
        }
        newData.push(newRecord);
        if (isIndividualPrice) newData.forEach(o => o.isIndividualPrice = true);
        props.dispatch({ type: props.type, value: newData });
        setNewIndex(index);
    };

    const cloneRecord = () => {
        const newData = CloneObject(data);
        const newRecord = {
            isNew: true
        };
        const lastRecord = newData[newData.length - 1];
        const index = (newIndex + 1);
        const cloneFields = [
            'pickLocationId', 'pickLocationWithState',
            'readyDate', 'temperature', 'batch', 'routeId', 'priceTemplateType',
            'pickRegionId', 'pickRegionName', 'pickRegionCode',
            'inOrderPossitionIndex', 'outOrderPossitionIndex'];

        if (lastRecord.batch === 1) {
            cloneFields.push('dueDate', 'deliverDate', 'drop2City', 'drop2State', 'dropLocationId', 'dropRegionId', 'orderIndicator');
        }

        for (const f in lastRecord) {
            if (f === 'isNew') {
                continue;
            }
            if (f === props.keyFieldName) {
                newRecord[f] = index * -1;
            }
            else if (f === 'orderStatus') {
                newRecord.orderStatus = 'Open';
            }
            else if (f === 'pickWhEfs') {
                newRecord.pickWhEfs = 0;
            }
            else if (f === 'dropWhEfs') {
                newRecord.dropWhEfs = 0;
            }
            // else if (f === 'palletCount' || f === 'space') {
            //     newRecord[f] = lastRecord[f];
            // }
            else if (cloneFields.indexOf(f) !== -1) {
                newRecord[f] = lastRecord[f];
            }
            else if (typeof lastRecord[f] === "boolean") {
                newRecord[f] = false;
            }
            else if (typeof lastRecord[f] === "number") {
                newRecord[f] = null;
            }
            else if (f === 'inOrderPossitionIndex' || f === 'outOrderPossitionIndex') {
                newRecord[f] = 1;
            }
            else {
                newRecord[f] = null;
            }
        }
        newData.push(newRecord);
        updatePrice(newData, ArraySum(newData.filter(o => !o.cancel), 'price'), isUseTotalPrice());
        if (isIndividualPrice) newData.forEach(o => o.isIndividualPrice = true);
        props.dispatch({ type: props.type, value: newData });
        setNewIndex(index);
    };

    const onChangeHandler = (event) => {
        changeRecordData(event.keyField, event.field, event.newData, event.column, event.nativeEvent.source);
    };

    const handlePriceUpdate = (keyField, responce, changedData) => {
        const newData = CloneObject(changedData);
        const item = newData.find(i => i[props.keyFieldName] === keyField);
        if (responce.price === 0)
        {
            setValue(item, 'priceTemplateType', { value: 1 });
            setValue(item, 'price', { value: null });
        }
        else if (responce.priceTemplateType > 1)
        {
            setValue(item, 'price', { value: responce.price });
        }
        props.dispatch({ type: props.type, value: newData });
    };

    const updatePriceByTemplate = (customerId, pickLockId, dropLockId, palletCount, weight, keyField, newData) => {
        const filterData = {
            params: {
                customerId: customerId,
                pickLockId: pickLockId,
                dropLockId: dropLockId,
                palletCount: palletCount,
                weight: weight,
            },
            headers: axiosConfig().headers,
        };
        axios.get(buildPriceUrl('Order/pricebytemplate'), filterData, axiosConfig())
            .then(responce => handlePriceUpdate(keyField, responce.data, newData));
    };

    const changeRecordData = (keyField, field, newValue, column, outSource) => {
        const newData = CloneObject(data);
        const item = newData.find(i => i[props.keyFieldName] === keyField);

        if (item) {
            setValue(item, field, {
                value: newValue,
                dependencies: column.Dependencies,
                outSource: outSource
            });
            if (field === 'palletCount') {
                setValue(item, 'space', {
                    value: newValue,
                    dependencies: column.Dependencies,
                    outSource: outSource
                });
                updatePrice(newData, ArraySum(newData.filter(o => !o.cancel), 'price'), isUseTotalPrice());
                const allSpacesAreFilleed = props.data.filter(order => order.space).length === props.data.length;
                if (allSpacesAreFilleed) {
                    validate()
                }
            }
            if (field === 'priceTemplateType') {
                const index = Math.abs(keyField)-1;
                let currentOrder = {};
                if (index === -1) {
                    currentOrder = newData[0];
                } else if (keyField > 1) {
                    currentOrder = item
                } else {
                    currentOrder = newData.filter((order, i) => i === index)[0];
                }
                if (currentOrder?.priceTemplateType > 1) { // if user choose Use template
                    if (currentOrder?.palletCount && currentOrder?.weight && currentOrder?.pickLocationId && currentOrder?.dropLocationId) {
                        props.dispatch({ type: props.type, value: newData });
                        updatePriceByTemplate(
                            customerId,
                            currentOrder.pickLocationId,
                            currentOrder.dropLocationId,
                            currentOrder.palletCount,
                            currentOrder.weight,
                            keyField,
                            newData);
                        return;
                    } else {
                        if (!isUseTotalPrice()) {
                            setValue(item, 'price', { value: 0 });
                        }
                    }
                } else {
                    if (!isUseTotalPrice()) {
                        setValue(item, 'price', { value: 0 });
                        setValue(item, 'priceTemplateType', { value: 1 });
                    }
                }
            }
            if (field === 'weight' || field === 'palletCount' || field === 'pickLocation' || field === 'dropLocationId') {
                const index = Math.abs(keyField)-1;
                let currentOrder = {};
                if (index === -1) {
                    currentOrder = newData[0];
                } else if (keyField > 1) {
                    currentOrder = item
                } else {
                    currentOrder = newData.filter((order, i) => i === index)[0];
                }

                if (currentOrder?.palletCount && currentOrder?.weight && currentOrder?.pickLocationId && currentOrder?.dropLocationId && batch === 'no') {
                    if (currentOrder?.priceTemplateType > 1) {
                        props.dispatch({ type: props.type, value: newData });
                        updatePriceByTemplate(
                            customerId,
                            currentOrder.pickLocationId,
                            currentOrder.dropLocationId,
                            currentOrder.palletCount,
                            currentOrder.weight,
                            keyField,
                            newData);
                        return;
                    }
                } else {
                    if (!isUseTotalPrice()) {
                        setValue(item, 'price', { value: 0 });
                    }
                }
            }
            

            newData.forEach(i => {
                i.isDuplicate = newData.filter(o => o.poNumber === i.poNumber).length >= 2;
            });
            props.dispatch({ type: props.type, value: newData });
        }
    };

    const setSoftBatch = () => {
        const newData = CloneObject(data);
        setBatch(BatchTypes.soft);
        newData.forEach(o => o.batch = 2);
        newData.forEach(o => o.isIndividualPrice = true);
        updatePrice(data, totalPrice.value, true);
        updatePriceType(newData, 1);
        props.dispatch({ type: props.type, value: newData });
        setIsIndividualPrice(true);
    };

    const setHardBatch = () => {
        const newData = CloneObject(data);
        setBatch(BatchTypes.hard);
        newData.forEach(o => o.batch = 1);
        newData.forEach(o => o.isIndividualPrice = true);
        updatePrice(newData, totalPrice.value, true);
        updatePriceType(newData, 1);
        props.dispatch({ type: props.type, value: newData });
        setIsIndividualPrice(true);
    };

    const setNoBatch = () => {
        const newData = CloneObject(data);
        setBatch(BatchTypes.no);
        newData.forEach(o => o.batch = 3);
        // updatePrice(newData, totalPrice.value, true);
        props.dispatch({ type: props.type, value: newData });
        setIsIndividualPrice(false);
    };

    const setCheckedBatch = isIndividualPrice => {
        setIsIndividualPrice(isIndividualPrice);
        const newData = CloneObject(data);
        updatePriceType(newData, 1);
        newData.forEach(o => o.isIndividualPrice = isIndividualPrice);
        updatePrice(newData, totalPrice.value, isIndividualPrice);
        props.dispatch({ type: props.type, value: newData });
    };

    const sheetMessageIdentityPrefix = props.messageIdentityPrefix == null ? `${props.type}` : `${props.messageIdentityPrefix}_${props.type}`;

    const setTotalPriceHandler = values => {
        const newData = CloneObject(data);
        const totalPrice = values /* ? parseFloat(values) : '' */;
        setTotalPrice({ value: totalPrice });
        updatePrice(newData, totalPrice, isUseTotalPrice());
        props.dispatch({ type: props.type, value: newData });
    };

    const updatePrice = (newData, totalPriceValue, isUseTotalPrice) => {
        if (isUseTotalPrice) {
            newData = newData.filter(o => !o.cancel);
            const totalPalletCount = ArraySum(newData, 'palletCount');
            let totalLeftOver = 0;
            let palletPrice = 0;
            const floor = 10;
            if (totalPalletCount > 0) {
                totalLeftOver = totalPriceValue % floor;
                totalPriceValue -= totalLeftOver % floor;
                palletPrice = Math.floor(totalPriceValue / totalPalletCount);
                totalLeftOver += totalPriceValue - (palletPrice * totalPalletCount);
            }
            newData.forEach((d, i) => {
                if (i) {
                    d.price = palletPrice * d.palletCount;
                } else {
                    d.price = palletPrice * d.palletCount + totalLeftOver;
                }
            });
        }
        setTotalPrice({ value: totalPriceValue });
    };

    const updatePriceType = (newData, value) => {
        newData.forEach(order => order.priceTemplateType = value);
    };

    const presentationData = CloneObject(data.filter(o => !o.cancel));

    presentationData.forEach((d, i) => {
        d.puWeek = d.puDate && new Date(d.puDate).getWeek();
        d.dropWeek = d.dropDate && new Date(d.dropDate).getWeek();
        d.customerId = props.object.customerId;
    });

    const dragDropData = {
        droppable: {
            data: data.filter(o => !o.cancel),
            dispatch: props.dispatch,
            type: props.type
        }
    };

    const isDeliverDataMatch = data.filter(order => !!order.deliverDate && !!order.deliverDate2 && order.deliverDate !== order.deliverDate2);

    const batchBlock = (<div className="line form_fields">
        <label radio="">
            <input name="batch" id={BatchTypes.soft} type="radio" value={BatchTypes.soft} checked={batch === BatchTypes.soft} onChange={setSoftBatch} />
            <box><check></check></box>
            <text className='order__batch-text'>Soft Batch</text>
        </label>
        <label radio="">
            <input name="batch" id={BatchTypes.hard} type="radio" value={BatchTypes.hard} checked={batch === BatchTypes.hard} onChange={setHardBatch} />
            <box><check></check></box>
            <text className='order__batch-text'>Hard Batch</text>
        </label>
        <label radio="">
            <input name="batch" id={BatchTypes.no} type="radio" value={BatchTypes.no} checked={batch === BatchTypes.no} onChange={setNoBatch} />
            <box><check></check></box>
            <text className='order__batch-text'>No Batch</text>
        </label>
        <input className='initialCheckbox' disabled={batch === BatchTypes.no || batch === BatchTypes.hard} style={{width: 'auto'}} type="checkbox" value={isIndividualPrice} checked={isIndividualPrice} onChange={() => setCheckedBatch(!isIndividualPrice)} />
    </div>);

    return (<React.Fragment>
        <toolbar scroll='scroll'>
            <Scroll>
                <wrap>
                    <action style={{ display: 'flex', flexDirection: 'column', alignItems: 'normal' }}>
                        {isDeliverDataMatch.length ?
                            <Tooltip title='Please note: The selected date does not match the calculated delivery date'>
                                <div style={{ color: 'red', fontSize: '12px', margin: '0 4px' }}>
                                    Please note: The selected date does not match the calculated delivery date
                                </div>
                            </Tooltip> : null}
                        <wrap>
                            <div className="line small form_fields">
                                <div className="data_label data_label__text">Pallet Count</div>
                                <div className="data_read"><text>{ArraySum(presentationData, 'palletCount')}</text></div>
                            </div>
                            <div className="line small form_fields">
                                <div className="data_label data_label__text">Total Weight</div>
                                <div className="data_read"><text>{ArraySum(presentationData, 'weight')?.toLocaleString()}</text></div>
                            </div>
                            {batchBlock}
                            {isUseTotalPrice() && isIndividualPrice ? (<div className="line form_fields">
                                <div className="data_label data_label__text">Total Rate</div>
                                <CurrencyInput className="data_label data_input__text" allowNegativeValue={false} maxLength={7} thousandseparator="true" prefix={'$'}
                                    onValueChange={setTotalPriceHandler}
                                    value={ArraySum(presentationData, 'price')} />
                            </div>) :
                                (<div className="line form_fields">
                                    <div className="data_label data_label__text">Total Rate</div>
                                    <div className="data_read"><text>${ArraySum(presentationData, 'price')?.toLocaleString()}</text></div>
                                </div>)
                            }
                        </wrap>
                    </action>
                </wrap>
            </Scroll>
        </toolbar>        
        <div className="form_container">
            <DragDrop {...dragDropData} dispatch={props.dispatch}>
                <Sheet
                    messageIdentityPrefix={sheetMessageIdentityPrefix}
                    keyFieldName={props.keyFieldName}
                    columns={columns}
                    data={presentationData}
                    onChange={onChangeHandler}
                    messageDispatch={props.messageDispatch}
                    messages={props.messages}
                    dispatch={props.dispatch}
                    type={props.type}
                    classes={'table_view compact'}
                    minRowsCanDelete={1}
                    colgroup
                    action
                    actions={actions}
                    onActionRecord={onActionRecordHandler}
                    draggable
                />
            </DragDrop>
        </div>
        <toolbar>
            <button className='button primary' onClick={newRecordHandler}>
                <text>Add New Order</text>
            </button>
            <separator vertical=""></separator>
            <button className='button accent' onClick={cloneLastRecordHandler}>
                <text>Clone Last Order</text>
            </button>
            <action right='right'>
                    <wrap>
                        <div className="line small form_fields">
                            <div className="data_label">Created By</div>
                            <div className="create-date"><text>{props.object.created}</text></div>
                        </div>
                        <div className="line small form_fields">
                            <div className="data_label">Create Date</div>
                            <div className="create-date"><text>{props.object.createDate ? moment(new Date(props.object.createDate)).format(Defines.Format.PrintDate) : ''}</text></div>
                        </div>
                    </wrap>
                </action>
        </toolbar>
        
    </React.Fragment>);
});

const mapDispatchToProps = (dispatch) => {
    return {
        openAlert: (key) => dispatch(openPopup(key))
    };
};

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(Order);