import { ACCOUNT_RECEIVABLE_BUILD as actionTypes } from '../../actions/accountReceivableActions/accountReceivableActionTypes';
import * as loadArea from './accountReceivableReducer';

const pathname = window.location.pathname;

const defaultState = {
    headers: [
        {
            key: 'poNumber',
            title: 'PO#',
            width: 60,
            leftAction: false,
            visible: true
        },
        {
            key: 'soNumber',
            title: 'SO#',
            width: 60,
            leftAction: false,
            visible: true
        },
        {
            key: 'deliveredDate',
            title: 'Delivery Date',
            width: 110,
            leftAction: false,
            visible: true,
            sortable: true,
        },
        // {
        //     key: 'referenceNumber',
        //     title: 'Row',
        //     width: 60,
        //     leftAction: false,
        //     visible: true
        // },
        {
            key: 'customerName',
            title: 'Customer',
            width: 110,
            leftAction: false,
            visible: true
        },
        {
            key: 'batchType',
            title: 'Batch',
            width: 100,
            leftAction: false,
            visible: true
        },
        {
            key: 'pickLocationName',
            title: 'Pick Up WH',
            width: 160,
            leftAction: false,
            visible: true
        },
        {
            key: 'drop2WH',
            title: 'Delivery Warehouse',
            width: 160,
            leftAction: false,
            visible: true
        },
        {
            key: 'routeId',
            title: 'Route',
            width: 60,
            leftAction: false,
            visible: true
        },
        {
            key: 'puDate',
            title: 'Pue Date',
            width: 80,
            leftAction: false,
            visible: true,
            pickGroup: true
        },
        {
            key: 'dueDate',
            title: 'Due Date',
            width: 80,
            leftAction: false,
            visible: true,
            pickGroup: true,
        },
        {
            key: 'palletCount',
            title: 'Pal',
            width: 40,
            leftAction: false,
            visible: true,
            pickGroup: true,
        },
        {
            key: 'space',
            title: 'Space',
            width: 40,
            leftAction: false,
            visible: true,
            pickGroup: true,
        },
        {
            key: 'cases',
            title: 'Cases',
            width: 40,
            leftAction: false,
            visible: true,
            dropGroup: true
        },
        {
            key: 'weight',
            title: 'LBS',
            width: 50,
            leftAction: false,
            visible: true
        },
        {
            key: 'price',
            title: 'Price',
            width: 40,
            leftAction: false,
            visible: true
        },
        {
            key: 'lumper',
            title: 'Lumper',
            width: 60,
            leftAction: false,
            visible: true
        },
        {
            key: 'podRequestStatus',
            title: 'POD Request',
            width: 80,
            leftAction: false,
            visible: true,
        },
        {
            key: 'podDoc',
            title: 'POD Doc.',
            width: 100,
            leftAction: false,
            visible: true
        },
        {
            key: 'orderNotesByCall',
            title: 'POD Status',
            width: 70,
            leftAction: false,
            visible: true
        },
        {
            key: 'notes',
            title: 'Notes',
            width: 40,
            leftAction: false,
            visible: true
        },
        {
            key: 'orderGroupIndex',
            title: 'Group',
            width: 60,
            leftAction: false,
            visible: true
        },
        {
            key: 'actions',
            title: 'Action',
            width: 60,
            leftAction: false,
            visible: true
        }
    ],
    openOrdersHeaders: [
        {
            key: 'temperature',
            title: 'Temp',
            width: 60,
            leftAction: false,
            visible: true
        },
        {
            key: 'dueDate',
            title: 'Due/D',
            width: 60,
            leftAction: false,
            visible: true,
            pickGroup: true,
        },
        {
            key: 'deliveredDate',
            title: 'Del/D',
            width: pathname === "/ar/openorders" || pathname === "/ar/changerequestsar" ? 60 : 110,
            leftAction: false,
            visible: true,
            sortable: true
        },
        {
            key: 'customerName',
            title: 'Customer',
            width: 110,
            leftAction: false,
            visible: true
        },
        {
            key: 'pickLocationName',
            title: 'Pick Up WH',
            width: 160,
            leftAction: false,
            visible: true
        },
        {
            key: 'drop2WH',
            title: 'Delivery Warehouse',
            width: 160,
            leftAction: false,
            visible: true
        },
        {
            key: 'routeId',
            title: 'Route',
            width: 50,
            leftAction: false,
            visible: true
        },
        {
            key: 'poNumber',
            title: 'PO#',
            width: 60,
            leftAction: false,
            visible: true
        },
        {
            key: 'soNumber',
            title: 'SO#',
            width: 60,
            leftAction: false,
            visible: true
        },
        {
            key: 'palletCount',
            title: 'Pallet',
            width: 40,
            leftAction: false,
            visible: true,
            pickGroup: true,
        },
        {
            key: 'space',
            title: 'Space',
            width: 40,
            leftAction: false,
            visible: true,
            pickGroup: true,
        },
        {
            key: 'cases',
            title: 'Case',
            width: 40,
            leftAction: false,
            visible: true,
            dropGroup: true
        },
        {
            key: 'weight',
            title: 'LBS',
            width: 50,
            leftAction: false,
            visible: true
        },
        {
            key: 'batchType',
            title: 'Batch',
            width: 50,
            leftAction: false,
            visible: true
        },
        // {
        //     key: 'freight',
        //     title: 'Freight',
        //     width: 60,
        //     leftAction: false,
        //     visible: true
        // },
        {
            key: 'orderGroupIndex',
            title: 'Group',
            width: 50,
            leftAction: false,
            visible: true
        },
    ],
    lastSelected: {
        id: null,
        headerKey: null,
        enable: false
    },
    orders: null,
    loads: null,
    loadsAssignedOrders: null,
    loading: true,
    changeRequestListLoading: true,
    openOrdersLoading: true,
    hasError: false,
    message: '',
    filterType: '',
    sortType: {type: "", ind: "", sort: null},
    orderId: '',
    priceIsCalculated: [],
};
const defaultStateClone = {...defaultState};

export default (state = defaultState, action) => {
    const pathname = window.location.pathname;
    const newHeaders = defaultStateClone.headers.filter(item => item.key !== "actions")
    if (pathname === "/ar/readytobill"){
        state.headers = 
        [
            {
                key: 'leftAction',
                title: '',
                width: 36,
                leftAction: true,
                visible: true
            },
            ...newHeaders, 
            {
                key: 'readyToBill',
                title: '',
                width: 110,
                leftAction: false,
                visible: true,
            },
            {
                key: 'actions',
                title: 'Action',
                width: 60,
                leftAction: false,
                visible: true
            }
        ]
    }
    if (pathname === "/ar/alreadybilled" && !state.headers.filter(item => item.key === "status" ).length) {
        state.headers = [
            {
                key: 'leftAction',
                title: '',
                width: 36,
                leftAction: true,
                visible: true
            },
            ...newHeaders, 
            {
            key: 'status',
            title: 'Status',
            width: 110,
            leftAction: false,
            visible: true,

        },{
            key: 'billedBy',
            title: 'Billed by',
            width: 110,
            leftAction: false,
            visible: true,

        },{
            key: 'billedDate',
            title: 'Billed Date',
            width: 150,
            leftAction: false,
            visible: true,

        },{
            key: 'actions',
            title: 'Action',
            width: 60,
            leftAction: false,
            visible: true
        }
        ]
    }
    if (pathname === "/ar/deliverednopod"){
        state.headers = [
            {
                key: 'leftAction',
                title: '',
                width: 36,
                leftAction: true,
                visible: true
            },
            ...defaultStateClone.headers
        ]
    }
    if (pathname === "/ar/openorders") {
        state.headers = [...defaultState.openOrdersHeaders]
    }
    if (pathname === "/ar/changerequestsar") {
        state.headers = [
            ...defaultState.openOrdersHeaders,
            {
                key: 'price',
                title: 'Price',
                width: 50,
                leftAction: false,
                visible: true,
            }, {
                key: 'priceTemplateType',
                title: 'Price calculation option',
                width: 100,
                leftAction: false,
                visible: true
            }, {
                key: 'recalculation',
                title: 'Recalculation',
                width: 80,
                leftAction: false,
                visible: true
            }, {
                key: 'changeHistory',
                title: 'Change History',
                width: 90,
                leftAction: false,
                visible: true,
            }, {
                key: 'confirm',
                title: 'Confirm',
                width: 60,
                leftAction: false,
                visible: true
            }
        ]
    };

    switch (action.type) {
        case actionTypes.GET_ACCOUNT_RECEIVABLE:
            return loadArea.loadContent(state, action.data);
        case actionTypes.ACCOUNT_RECEIVABLE_ERROR:
            return loadArea.setError(state, action.data);
        case actionTypes.RESET_ERROR:
            return loadArea.resetError(state);
        case actionTypes.FILTER_ACCOUNT_RECEIVABLE:
            return loadArea.filterDeliveredTrucks(state, action.data);
        case actionTypes.SORT_ACCOUNT_RECEIVABLE:
            return loadArea.sortBy(state, action.data);
        case actionTypes.GET_ACCOUNT_RECEIVABLE_LOADING:
            return loadArea.setInLoading(state, action.data);
        case actionTypes.RELOAD_ON_ERROR_ACCOUNT_RECEIVABLE:
            return loadArea.loadContent(state, action.data, true, 'Something go wrong, data reloaded!');
        case actionTypes.SET_HEADER_WIDTH_ACCOUNT_RECEIVABLE:
            return loadArea.setHeaderWidth(state, action.data.ind, action.data.width);
        case actionTypes.GET_CHANGE_REQUEST_DATA:
            return loadArea.getChangeRequestData(state, action.data);
        case actionTypes.GET_CHANGE_REQUEST_DATA_LOADING:
            return loadArea.setInLoading(state, action.data);
        case actionTypes.GET_CHANGE_REQUEST_DATA_ERROR:
            return loadArea.setError(state, action.data);
        case actionTypes.SET_ACCOUNT_RECEIVABLE_DATA:
            return loadArea.setData(state, action.data.value, action.data.rowState, action.data.header);
        case actionTypes.SET_ORDER_ID:
            return loadArea.setOrderId(state, action.data);
        case actionTypes.SET_CHECKED:
            return loadArea.setChecked(state, action.data);     
        case actionTypes.UPDATE_PRICE:
            return loadArea.updatePrice(state, action.data);
        case actionTypes.RECALCULATE_PRICE:
            return loadArea.reCalculatePrice(state, action.data);
        case actionTypes.SET_PRICE_AS_CALCULATED:
            return loadArea.setPriceAsCalculated(state, action.data);
        case actionTypes.REACTED_ASSIGNED_AR:
            return loadArea.reactedAssigned(state, action.data.isSub, action.data.id);
        default:
            return state;
    }
};