import moment from 'moment';
import { getEfsStatusTooltip, getEfsBGColor } from './dataGeneral';
export const itemDefaultSize = 20;
const toDateTime = value => value ? moment.utc(value).toDate() : '';

const extractWebLoadsToList = state => (loads) => {
    let loadsAssignedOrders = [];
    loads.forEach(loadItem => {
        loadsAssignedOrders.push(loadItem);
        loadItem.ordersData.forEach(order => {
            loadsAssignedOrders.push(order);
        })
        loadsAssignedOrders = [...loadsAssignedOrders, ...extractWebLoadsToList(state)(loadItem.subLoads)];
    });

    // remove loads without any stop/order
    const filteredLoadsAssignedOrders = loadsAssignedOrders
        .filter(order => order.rowState.type === 'order' || (order.rowState.type === 'load' && order.ordersData?.length > 0));

    return filteredLoadsAssignedOrders;
};


export const createSubOrder = (so, originalOrder, childSubOrder) => {
    return {
        ...originalOrder,
        ...{
            orderId: so.orderId,
            parentId: so.parentId,
            subOrderId: so.id,
            loadId: so.loadId,
            originalOrderId: so.orderId,
            pickLocationId: so.dropLocationId,
            pickLocation: so.dropLocation,
            // dropLocationId: childSubOrder ? childSubOrder?.dropLocationId : originalOrder?.dropLocationId,
            // dropLocation: childSubOrder ? childSubOrder?.dropLocation : originalOrder?.dropLocation,
            inboundPickIn: so.pickInDate,
            inboundPickOut: so.pickOutDate,
            inboundAppointmentStatus: so.pickAppointmentStatus,
            outboundPickAppointment: so.pickAppointmentDate,
            outboundDropAppointment: so.dropAppointmentDate,
            puDate: so.puDate,
            dueDate: so.dueDate,
            deliverDate: so.deliverDate,
            inboundDropIn: so.dropInDate,
            inboundDropOut: so.dropOutDate,
            inboundLumper: so.lumper,
            dropRestriction: so.dropRestriction,
            pickRestriction: so.pickRestriction,
            pickInDate: so.pickInDate,
            pickOutDate: so.pickOutDate,
            dropInDate: so.dropInDate,
            dropOutDate: so.dropOutDate,
            outboundPickIn: so.pickInDate,
            outboundPickOut: so.pickOutDate,
            outboundDropIn: so.dropInDate,
            outboundDropOut: so.dropOutDate,
            stepCount: so.stepCount,
            carrierName: so.carrierName,
            palletCount: so.palletCount,
            cases: so.cases,
            space: so.space,
            weight: so.weight,
            poNumber: so.poNumber,
            callCheckOutAttachment: so.callCheckOutAttachment,
            outPodRequestStatus: so.outPodRequestStatus,
            outNotes: so.outNotes,
            readyDate: so.readyDate,
            subDropLocation: so.subDropLocation,
            soNumber: so.soNumber,
            loadNum: so.loadNum,
        }
    }
};

const createWebLoadArray = state => (loadsData, assignedOrders, subOrders, headers, oldLoadsAssignedOrders) => {
    //create sub orders
    const subOrdersData = [];
    subOrders.forEach(so => {
        subOrdersData.push(createSubOrder(
            so, 
            assignedOrders.find(o => o.id === so.orderId),
            subOrders.find(fso => fso.orderId === so.orderId && fso.parentId === so.id)
        ));
    });
    const subLoads = loadsData.filter(load => load.parentLoadId);

    const loads = [];
    // add loads and orders
    loadsData
        .filter(l => !l.parentLoadId)
        .forEach(load => loads.push(createWebLoad(
            load, 
            assignedOrders.filter(o => o['loadId'] === load.id || o['outboundLoadId'] === load.id),
            subLoads,
            subOrdersData,
            headers,
            (oldLoadsAssignedOrders || []).find(ol => ol.rowState.type === 'load' && ol.rowState.loadData.id === load.id),
            false
        )));

    // render subLoads/subOrders without Main Load
    const subOrdersOrderIDs = subOrdersData.map(subO => subO.loadId);
    const allLoadIDs = loadsData.map(load => load.id);
    if (subLoads.length > 0) {
        subLoads
            .filter(subL => subOrdersOrderIDs.includes(subL.id))
            .filter(subL => !allLoadIDs.includes(subL.parentLoadId))
            .forEach(subLoad => loads.push(createWebLoad (
                subLoad, 
                subOrdersData.filter(subOrder => subOrder.loadId === subLoad.id),
                subLoads,
                subOrdersData,
                headers,
                (oldLoadsAssignedOrders || []).find(ol => ol.rowState.type === 'load' && ol.rowState.loadData.id === subLoad.id),
                true
            )));
    }

    if (assignedOrders.length !== loads.reduce((r, o) => r += o.ordersData.length, 0)) {
        console.log('Some assigned orders not found there loads');
    }

    return loads;
};

export const updateOrdersLoadState = (state, orders, loads, subOrders, hasError = false, message = '', filterType = 'all', sortType = {type: "", ind: "", sort: null}) => ({
    ...state, ...{
        orders,
        loads,
        subOrders,
        loading: false,
        hasError,
        message,
        filterType,
        sortType,
        loadsAssignedOrders:
            extractWebLoadsToList(state) (
                createWebLoadArray(state) (
                    loads,
                    orders,
                    subOrders,
                    state.headers,
                    state.loadsAssignedOrders), state.headers),
    }
});

export const loadContent = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    return updateOrdersLoadState(state, [...data.assignedOrders.data], data.loads.data, data.subOrders.data, hasError, message);
};

export const createWebLoad = (loadData, loadOrders, subLoads, subOrdersData, headers, oldWebLoad, isSub) => {
    const loadId = `load_${loadData.id}`;
    const loadWebData = {
        row: [],
        ordersData: [],
        subLoads: [],
        rowState: {
            type: 'load',
            isSub: isSub,
            subId: subLoads ? subLoads.find(l => l.parentLoadId === loadData.id)?.id : null,
            id: loadId,
            loadId: loadData.id,
            loadData,
            visible: true,
            reacted: oldWebLoad !== undefined && oldWebLoad?.rowState?.reacted,
            isChecked: oldWebLoad !== undefined && oldWebLoad?.rowState?.reacted,
            available: false,
            createPickKey: order => {
                let createKey = '0';
                if (order.loadId) {
                    createKey = `${order.loadId}_${order.pickLocationId}`;
                }
                return createKey;
            },
            createDropKey: order => {
                let createKey = '0';
                if (order.loadId) {
                    createKey = `${order.loadId}`;
                    if (order.warehouse) {
                        createKey = `${createKey}_0_${order.warehouseId}`;
                    }
                    else {
                        createKey = `${createKey}_${order.dropLocationId}_0`;
                    }
                }
                return createKey;
            },
            groups: (orders, keyFunction) =>  orders.reduce((r, o) => {
                    const groupBy = keyFunction(o.rowState.orderData);
                    r[groupBy] = [...r[groupBy] || [], o.rowState.orderData];
                    return r;
                }, {}),
            findOrderGroup: (groups, order, createKey) => {
                return Object.keys(groups).find(go => {
                    const oG = groups[go];
                    if (oG.some(e => e.id === order.id)) {
                        return groups[go];
                    }
                    if (createKey && go === createKey(order)) {
                        return groups[go];
                    }
                    return null;
                });
            },
            height: itemDefaultSize
        }
    };
    loadOrders.forEach(lo => loadWebData.ordersData.push(createWebOrder(lo, headers, loadData, loadId, loadWebData.rowState.reacted, isSub)));
    loadWebData.ordersData =
        loadWebData.ordersData.sort((a, b) => new Date(a.rowState.orderData.inboundPickAppointment) - new Date(b.rowState.orderData.inboundPickAppointment ?? ''));

    const pickInboundGroups = loadWebData.rowState.groups(loadWebData.ordersData, loadWebData.rowState.createPickKey);

    for (let hederIndex = 0; hederIndex < headers.length; hederIndex++) {
        const headerItem = headers[hederIndex];
        let value = null;
        let fieldType = 'text';
        let editable = false;
        let cellStyle = {};
        let tooltip = null;
        if (!headerItem.leftAction) {        
            switch (headerItem.key) {
                case 'readyToPay':
                    value = "SEND TO PAID";
                    fieldType = 'button';
                    break
                case 'paid':
                    value = "Paid";
                    fieldType = 'text';
                    break
                case 'sendToPayBy':
                    value = loadData?.payerFullName;
                    fieldType = 'text';
                    break
                case 'paidDate':
                    value = loadData?.paidDate;
                    fieldType = 'dateTime';
                    break;
                case 'efs':
                    value = loadData.efsSum ? `$${loadData.efsSum}` : '';
                    fieldType = 'currency';
                    cellStyle = getEfsBGColor(loadData.efsStatusMin);
                    tooltip = getEfsStatusTooltip(loadData.efsStatusMin);
                    break
                case 'driver_truck_Trailer_VIN':
                    value = `${(loadData.driverName ? loadData.driverName : '')} ${(loadData.truckNumber ? loadData.truckNumber : '')} ${(loadData.trailer ? loadData.trailer : '')}`;//??? + vin
                    fieldType = 'text';
                    break;
                case 'pickLocation':
                    if (loadWebData.ordersData.length !== 0) {
                        const order = loadWebData.ordersData[0];
                        if (order) {
                            value = order.row.find(f => f.key === headerItem.key).value;
                        }
                    }
                    break;
                case 'dropLocation':
                    if (loadWebData.ordersData.length !== 0) {
                        const order = loadWebData.ordersData[0];
                        if (order) {
                            value = order.row.find(f => f.key === headerItem.key).value;
                        }
                    }
                    break;
                case 'carrierName':
                    value = loadData.carrierName;
                    fieldType = 'text';
                    //editable = true;
                    break;
                case 'rate':
                    value = loadData.rate ? loadData.rate : '';
                    fieldType = 'currency';
                    //editable = true;
                    break;
                case 'additionalPay':
                    const val = `${loadData.amount? `$${loadData.amount}`:' '} ${loadData?.additionalPay}`
                    value = val;
                    fieldType = 'text';
                    break;
                case 'instructions':
                    value = loadData.notes ? loadData.notes : '';
                    fieldType = 'currency';
                    //editable = true;
                    break;
                case 'pickInDate':
                        value = loadData?.loadType === 1 ? `${Object.keys(pickInboundGroups).length}P` : '1P';
                    break;
                case 'palletCount':
                    if (loadWebData.ordersData.length > 0) {
                        value = loadWebData.ordersData.reduce((a, b) => {
                            return a + (b ?
                                +b.row.find(f => f.key === headerItem.key).value : 0);
                        }, 0);
                    }
                    fieldType = 'text';
                    break;
                case 'cases':
                    if (loadWebData.ordersData.length > 0) {
                        value = loadWebData.ordersData.reduce((a, b) => {
                            return a + (b ?
                                +b.row.find(f => f.key === headerItem.key).value : 0);
                        }, 0);
                    }
                    fieldType = 'text';
                    break;
                case 'space':
                    if (loadWebData.ordersData.length > 0) {
                        value = loadWebData.ordersData.reduce((a, b) => {
                            return a + (b ?
                                +b.row.find(f => f.key === headerItem.key).value : 0);
                        }, 0);
                    }
                    fieldType = 'text';
                    break;
                case 'weight':
                    if (loadWebData.ordersData.length > 0) {
                        value = loadWebData.ordersData.reduce((a, b) => {
                            return a + (b ?
                                +b.row.find(f => f.key === headerItem.key).value : 0);
                        }, 0);
                    }
                    fieldType = 'text';
                    break;
                case 'loadNumber':
                    value = loadData.loadNumber;
                    break;
                case 'readyDate':
                        value = '';
                        if (loadWebData.ordersData.length !== 0) {
                            const filterData = loadWebData.ordersData.filter(o => o.rowState.orderData[headerItem.key]);
                            if (filterData && filterData.length > 0) {
                                const maxDate = Math.max.apply(null,
                                    filterData.map(order => {
                                        return new Date(order.rowState.orderData[headerItem.key]);
                                    }));
                                if (maxDate) {
                                    value = new Date(maxDate);
                                }
                            }
                        }
                        fieldType = 'date';

                    break;
                case 'inboundPickAppointment':
                    value = '';
                    if (loadWebData.ordersData.length !== 0) {
                        const order = loadWebData.ordersData[loadWebData.ordersData.length - 1];
                        if (order) {
                            value = order.row.find(f => f.key === headerItem.key).value;
                        }
                    }
                    fieldType = 'dateTime';
                    break;
                default:
                    break;
            }
        }
        else {
            value = '';
            fieldType = 'text';
        }

        loadWebData.row.push({
            key: headerItem.key,
            value,
            fieldType,
            displayState: 1,
            editable,
            cellStyle,
            tooltip
        });
    }

    if (subLoads) {
        subLoads
        .filter(sl => sl.parentLoadId === loadData.id)
        .forEach(sl => {
            loadWebData.subLoads.push(createWebLoad(
                sl, 
                subOrdersData.filter(so => so.loadId === sl.id),
                subLoads, 
                subOrdersData, 
                headers, 
                oldWebLoad, 
                true));
        });
    }

    return loadWebData;
};

export const createWebOrder = (oData, headers, loadData, loadId, isHidden, isSub) => {
    const orderData = { ...oData };

    const orderWebData = {
        row: [],
        rowState: {
            type: 'order',
            id: `order_${orderData.id}_${loadId}`,
            isSub: isSub,
            orderObjectId: orderData.id,
            loadObjectId: loadData && loadData.id,
            orderData: orderData,
            loadData,
            orderId: `order_${orderData.id}`,
            loadId: loadId && loadId,
            visible: !isHidden,
            reacted: false,
            available: loadData === null || !loadData.id,
            height: itemDefaultSize,
        }
    };
    const pathname = window.location.pathname;

    for (let hederIndex = 0; hederIndex < headers.length; hederIndex++) {
        const headerItem = headers[hederIndex];
        let value = null;
        let value2 = null;
        let fieldType = 'text';
        let editable = false;
        let cellStyle = {};
        let tooltip = null;
        if (!headerItem.leftAction) {
            switch (headerItem.key) {
                case 'id':
                    value = orderData.id;
                    break;
                case 'pickInDate':
                    value = loadData.loadType === 1 ? toDateTime(orderData.inboundPickIn): loadData.loadType === 3 ? toDateTime(orderData.outboundPickIn) : '';
                    fieldType = 'dateTime';
                    break;
                case 'lumper':
                    value = loadData.loadType === 1 ? orderData.inboundLumper: loadData.loadType === 3 ? orderData.outboundLumper : '';
                    fieldType = 'currency';
                    break;
                case 'dellAppt':
                    value = loadData.loadType === 1 ? toDateTime(orderData.inboundDropAppointment): loadData.loadType === 3 ? toDateTime(orderData.outboundDropAppointment) : '';
                    fieldType = 'dateTime';
                    break;
                case 'pickOutDate':
                    value = loadData.loadType === 1 ? toDateTime(orderData.inboundPickOut): loadData.loadType === 3 ? toDateTime(orderData.outboundPickOut) : '';
                    fieldType = 'dateTime';
                    break;
                case 'readyDate':
                    value = toDateTime(orderData.readyDate);
                    fieldType = 'date';
                    break;
                case 'in_load':
                    value = orderData.inLoad;
                    break;
                case 'out_load':
                    value = orderData.outLoad;
                    break;
                case 'loadNumber':
                    value = loadData.loadNumber;
                    break;
                case 'wk':
                    value = orderData.puDate && new Date(orderData.puDate).getWeek();
                    fieldType = 'text';
                    break;
                case 'space':
                    value = orderData.space;
                    fieldType = 'text';
                    break;
                case 'dueDate':
                    value = toDateTime(orderData.dueDate);
                    fieldType = 'date';
                    editable = false;
                    break;
                case 'dropInDate':
                    value = loadData.loadType === 1 ? toDateTime(orderData.inboundDropIn): loadData.loadType === 3 ? toDateTime(orderData.outboundDropIn) : '';
                    fieldType = 'dateTime';
                    break;
                case 'dropOutDate':
                    value = loadData.loadType === 1 ? toDateTime(orderData.inboundDropOut): loadData.loadType === 3 ? toDateTime(orderData.outboundDropOut) : '';
                    fieldType = 'dateTime';
                    break;
                case 'customerCodeOrName':
                    value = orderData.customerCodeOrName;
                    fieldType = 'text';
                    break;
                case 'dropLocation':
                    value = orderData.subDropLocation;
                    fieldType = 'text';
                    break;
                case 'pickLocation':
                    value = orderData.pickLocation;
                    fieldType = 'text';
                    break;
                case 'inboundPickAppointment':
                    value = loadData.loadType === 1 ? toDateTime(orderData.inboundPickAppointment): loadData.loadType === 3 ? toDateTime(orderData.outboundPickAppointment) : '';
                    fieldType = 'dateTime';
                    break;
                case 'poNumber':
                    value = orderData.poNumber;
                    value2 = orderData.stepCount;
                    fieldType = 'textInputPO';
                    editable = orderData.stepCount ? true : false;
                    break;
                case 'soNumber':
                    value = orderData.soNumber;
                    fieldType = 'textInput';
                    break;
                case 'palletCount':
                    value = orderData.palletCount;
                    fieldType = 'integer';
                    break;
                case 'cases':
                    value = orderData.cases;
                    fieldType = 'integer';
                    break;
                case 'weight':
                    value = orderData.weight;
                    fieldType = 'integer';
                    break;
                case 'podRequestStatus':
                    let tempValue =  loadData.loadType === 1 ? orderData.inPodRequestStatus : loadData.loadType === 3 ? orderData.outPodRequestStatus : '';
                    if(pathname === "/ap/deliveredtrucks"){
                        value = tempValue
                        editable = true;
                        fieldType = 'select';
                    } else {
                        const selectOptionsPOD = { null: '-', 0: '-', 1: 'Received', 2: 'Requested', 3: 'Don’t Split' };
                        value = selectOptionsPOD[tempValue]
                        editable = false;
                        fieldType = 'text';
                    }
                    tooltip = loadData?.loadType === 1 && orderData?.inPodRequestAuthorFullName?.trim() ?
                        `Last Changed: ${orderData?.inPodRequestAuthorFullName}` :
                        loadData.loadType === 1 && !orderData?.inPodRequestAuthorFullName?.trim() ?
                        `No changes` :
                        loadData.loadType === 3 && orderData?.outPodRequestAuthorFullName?.trim() ?
                        `Last Changed: ${orderData?.outPodRequestAuthorFullName}` :
                        loadData.loadType === 3 && !orderData?.outPodRequestAuthorFullName?.trim() ?
                        `No changes` : '';
                    break;
                case 'podDoc':
                    value = loadData.loadType === 1 ? orderData.callCheckInAttachment?.fileName : loadData.loadType === 3 ? orderData.callCheckOutAttachment?.fileName : '';
                    if(pathname === "/ap/deliveredtrucks"){
                        editable = true;
                        fieldType = 'upload';
                    } else {
                        fieldType = 'download';
                        editable = false;
                    }
                    tooltip = loadData.loadType === 1 && orderData.callCheckInAttachment?.fullName ?
                        `Last Changed: ${orderData.callCheckInAttachment?.fullName}` :
                        loadData.loadType === 1 && !orderData.callCheckInAttachment?.fullName ?
                        `No changes` :
                        loadData.loadType === 3 && orderData.callCheckOutAttachment?.fullName ?
                        `Last Changed: ${orderData?.callCheckOutAttachment?.fullName}` :
                        loadData.loadType === 3 && !orderData.callCheckOutAttachment?.fullName ?
                        `No changes` : '';
                    break;
                case 'orderNotesByCall':
                    // value = loadData.loadType === 1 ? orderData.inNotes : loadData.loadType === 3 ? orderData.outNotes : '';
                    value2 = loadData.loadType === 1 ? orderData.inPodNotesHistory : loadData.loadType === 3 ? orderData.outPodNotesHistory : '';
                    // value = loadData.loadType === 1 ? orderData.inNotes : loadData.loadType === 3 ? orderData.outNotes : '';
                    // fieldType = 'select';
                    // editable = true;
                    // break;
                    let tempValueOrderNotes =  loadData.loadType === 1 ? orderData.inNotes : loadData.loadType === 3 ? orderData.outNotes : ''
                    if(pathname === "/ap/deliveredtrucks"){
                        value = tempValueOrderNotes
                        editable = true;
                        fieldType = 'select_and_popover';
                    } else {
                        const selectOptionsPODStatus = { 'Delivered': 'Delivered', 'Shortage': 'Shortage', 'Overage': 'Overage', 'Reject': 'Reject' };
                        value = selectOptionsPODStatus[tempValueOrderNotes]
                        editable = false;
                        fieldType = 'popover';
                    }
                    break;
                case 'actions':
                    value = 'text';
                    fieldType = 'actions';
                    break;
                default:
                    break;
            }
        }
        else {
            value = '';
            fieldType = 'text';
        }

        orderWebData.row.push({
            key: headerItem.key,
            value,
            value2,
            fieldType,
            displayState: 1,
            editable,
            cellStyle,
            tooltip
        });
    }

    return orderWebData;
};

export const setHeaderWidth = (state, ind, width) => {
    const header = state.headers[ind];
    header.width = width;
    return {
        ...state, ...{
            headers: [
                ...state.headers.slice(0, ind),
                ...[header],
                ...state.headers.slice(ind + 1)
            ],
            loadsAssignedOrders: state.loadsAssignedOrders,
        }
    };
};

export const filterDeliveredTrucks = (state, type) => {
    return {
        ...state,
        ...{
            filterType: type,
        },
    }
};

export const sortBy = (state, sortValue) => ({
    ...state,
    ...{
        sortType: sortValue,
    },
});

export const reactedAssigned = (state, type, id) => {
    const loadsAssignedOrders = state.loadsAssignedOrders;
    const index = loadsAssignedOrders.findIndex(i => i.rowState.id === id && i.rowState.type === type);
    const item = loadsAssignedOrders[index];

    if (item && item.rowState.type !== 'order') {
        item.rowState.reacted = !item.rowState.reacted;

        if (type === 'load') {
            return {
                ...state, ...{
                    headers: state.headers,
                    loadsAssignedOrders:
                    [
                        ...state.loadsAssignedOrders.slice(0, index),
                        ...[item],
                        ...state.loadsAssignedOrders.slice(index + 1)
                    ],
                }
            }
        }
    }
    return state;
};

export const setData = (state, newValue, rowState, header) => {
    if (rowState.orderData) {
        const newOrders = [...state.orders];
        const changedOrder = newOrders.find(o => o.id === rowState.orderData.id);

        if (changedOrder) {
            const subOrders = [...state.subOrders];
            const subOrdersOfChangedOrder = subOrders.filter(sub => sub.orderId === changedOrder.id)

            if (header.key === 'orderNotesByCall') {
                if (rowState.loadData.loadType === 3) {
                    changedOrder['outNotes'] = newValue;
                } else if (rowState.loadData.loadType === 1) {
                    changedOrder['inNotes'] = newValue;
                }
            } else if (header.key === 'podRequestStatus') {
                if (newValue === 3 && rowState.loadData.loadType === 3) {
                    subOrdersOfChangedOrder.forEach(subOrder => {
                        subOrder['outPodRequestStatus'] = newValue;
                    })
                } else if (changedOrder['outPodRequestStatus'] === 3 && newValue !== 3 && rowState.loadData.loadType === 3) {
                    subOrdersOfChangedOrder.forEach(subOrder => {
                        subOrder['outPodRequestStatus'] = 0;
                    })
                } 
                if (rowState.loadData.loadType === 3) {
                    changedOrder['outPodRequestStatus'] = newValue;
                } else if (rowState.loadData.loadType === 1) {
                    changedOrder['inPodRequestStatus'] = newValue;
                }
                // remove uploaded file if we change the status into "Don't split" from Received and order doesn't have sub
                if (newValue === 3) {
                    if (rowState.loadData.loadType === 3) {
                        changedOrder['callCheckOutAttachment'] = null;
                    } else if (rowState.loadData.loadType === 1) {
                        changedOrder['callCheckInAttachment'] = null;
                    }
                }
                if (rowState.loadData.loadType === 3 && ((newValue === 0 || newValue === 2) && (rowState?.orderData?.outPodRequestStatus === 1 || rowState?.orderData?.outPodRequestStatus === 3))) {
                    changedOrder['callCheckOutAttachment'] = null;
                }
                if (rowState.loadData.loadType === 1 && ((newValue === 0 || newValue === 2) && (rowState?.orderData?.inPodRequestStatus === 1 || rowState?.orderData?.inPodRequestStatus === 3))) {
                    changedOrder['callCheckInAttachment'] = null;
                }
            } else if (header.key === 'podDoc') {
                if (rowState.loadData.loadType === 3) {
                    changedOrder['callCheckOutAttachment'] = newValue;
                    if (changedOrder['outPodRequestStatus'] === 3) {
                        subOrdersOfChangedOrder.forEach(subOrder => {
                            subOrder['callCheckOutAttachment'] = newValue;
                        })
                    } 
                } else if (rowState.loadData.loadType === 1) {
                    changedOrder['callCheckInAttachment'] = newValue;
                }
            } else if (header.key === 'loadCallCheckNote') {
                if (rowState.loadData.loadType === 3) {
                    const stop = rowState.loadData.stops.filter(stop => stop.locationId === changedOrder.dropLocationId);
                    if (stop && stop[0]) {
                        stop[0].notes = newValue;
                    } else if (!stop[0]) {
                        rowState.loadData.stops.push({ notes: newValue, locationId: changedOrder.dropLocationId })
                    }
                } else if (rowState.loadData.loadType === 1) {
                    const stop = rowState.loadData.stops.filter(stop => stop.locationId === changedOrder.pickLocationId);
                    if (stop && stop[0]) {
                        stop[0].notes = newValue;
                    } else if (!stop[0]) {
                        rowState.loadData.stops.push({ notes: newValue, locationId: changedOrder.pickLocationId })
                    }
                }
            } else changedOrder[header.key] = newValue;
        }

        //sub order set
        const newSubOrders = [...state.subOrders];
        let changedSubOrder;
        if (rowState.orderData?.subOrderId) {
            changedSubOrder = newSubOrders.find(o => o.id === rowState.orderData?.subOrderId)
            || newSubOrders.find(o => o.orderId === rowState.orderData?.id);
        // when update the stop
        } else if (rowState.firstOrderData?.subOrderId) {            
            changedSubOrder = newSubOrders.find(o => o.id === rowState.firstOrderData?.subOrderId)
            || newSubOrders.find(o => o.orderId === rowState.firstOrderData?.id);
        }

        if (changedSubOrder) {
            if (header.key === 'podRequestStatus') {
                if (rowState.loadData.loadType === 3) {
                    changedSubOrder['outPodRequestStatus'] = newValue;
                } else if (rowState.loadData.loadType === 1) {
                    changedSubOrder['inPodRequestStatus'] = newValue;
                }
                // remove uploaded file if we change the status into "Don't split" from Received and order doesn't have sub
                if (newValue === 3) {
                    if (rowState.loadData.loadType === 3) {
                        changedSubOrder['callCheckOutAttachment'] = null;
                    } else if (rowState.loadData.loadType === 1) {
                        changedSubOrder['callCheckInAttachment'] = null;
                    }
                }
                if (rowState.loadData.loadType === 3 && ((newValue === 0 || newValue === 2) && (rowState?.orderData?.outPodRequestStatus === 1 || rowState?.orderData?.outPodRequestStatus === 3))) {
                    changedSubOrder['callCheckOutAttachment'] = null;
                }
                if (rowState.loadData.loadType === 1 && ((newValue === 0 || newValue === 2) && (rowState?.orderData?.inPodRequestStatus === 1 || rowState?.orderData?.inPodRequestStatus === 3))) {
                    changedSubOrder['callCheckInAttachment'] = null;
                }
            } else if (header.key === 'podDoc') {
                changedSubOrder['callCheckOutAttachment'] = newValue;
                changedSubOrder['callCheckAttachment'] = newValue;
            } else if (header.key === 'orderStatusByCall') {
                if (rowState.loadData.loadType === 3) {
                    changedSubOrder['outOrderStatusByCall'] = newValue;
                    if (newValue !== 2) {
                        changedSubOrder['outDelayedReason'] = null;
                    }
                }
            } else if (header.key === 'orderNotesByCall') {
                if (rowState.loadData.loadType === 3) {
                    changedSubOrder['outNotes'] = newValue;
                    changedSubOrder['orderNotesByCall'] = newValue;
                }
            } else if (header.key === 'delayedReason') {
                changedSubOrder['outDelayedReason'] = newValue;
            } else {
                changedSubOrder[header?.key] = newValue;
            }
        }

        return updateOrdersLoadState(state, newOrders, state.loads, newSubOrders, state.hasError, state.message, state.filterType, state.sortType);
    } else {
        return state;
    }
};

export const getHistoryListDeliveredTrucks = (state, response) => {
    const data = response.data.data || [];
    return {
        ...state,
        historyList: data,
        historyLoading: false,
    }
};

export const setHistoryLoadingDeliveredTrucks = state => ({
    ...state, ...{
        historyLoading: true
    }
});

export const setDisplayState = (state, rowState, header, enable) => ({
    ...state, ...{
        lastSelected: {
            id: rowState.id,
            headerKey: header.key,
            enable: enable
        }
    }
});

export const setError = (state, err) => ({
    ...state,
    ...{
        hasError: true,
        message: 'Something went wrong, please try again',
    },
});

export const resetError = state => ({
    ...state,
    ...{
        hasError: false,
        message: ''
    }
});

export const setInLoading = state => ({
    ...state, ...{
        loading: true
    }
});
